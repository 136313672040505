<template>
<div class="container">
<el-input v-model="input" placeholder="请输入要搜索的标题内容或直接点击下方标签检索"></el-input>
  
  
  <tag @tag-selected="handleTagSelected"></tag>
  
  <div v-for="(item, index) in list">
    <billItem :item="item" :index="index" @delete-bill="deleteBill"></billItem>


  </div>

<div class="block" v-if="!queryMode">
  <div class="pageContainer">
  <el-pagination style="text-align:center"
    layout="pager"
    :page-size="pageSize"
    :total="allBillsNum"
    page-count=7
    @currentChange="getPageBill"
    ></el-pagination>
</div> 
  </div>

</div>
</template>

<script>
import {getResNew, postResNew} from "../util/axiosAPI";
import { ElMessage } from "element-plus/es";
import billItem from '@/components/billItem'
import tag from '@/components/tag'
export default {
  name: "moneyList",
  
  mounted() {
    const _this = this;
    
 
    getResNew(
        '/bill/allBills/1/'+_this.pageSize + '/default').then(
        res => {
          _this.list = res;
          _this.allBillList=  res;
        }
    )

    getResNew('/bill/allBillsNum').then(
      res => {
  
        _this.allBillsNum = res;
      }
    )
  },
  data(){
    return{
      allBillsNum:0,
      pageSize:6,
      input:'',
      pageIndex:1,
      allBillList:[],
      list:[
      ],
      tags:[],
      queryMode:false
    }
  },
  methods:{
    deleteBill(item,index){
      let is_confirm = confirm("是否要删除账单：" + item.bill.title + "?");
      if(is_confirm){
        const _this = this;
        postResNew(
          '/bill/deleteBill/' + item.bill.id,
          {}
        ).then(
          res =>{
          
            if(res.code === 200){
            ElMessage({
              message:'删除成功',
              type:'success'
            });
              _this.list.splice(index,1);
            }
            
          }
        )
      }
    },
    handleTagSelected(tagText){
    const _this = this;
    const index = this.tags.indexOf(tagText);
    if(index === -1){
    this.tags = [tagText];
    }else{
      this.tags.splice(index, 1);

      if(this.tags.length === 0){
        this.list = this.allBillList;
        this.queryMode = false;
        return;
      }

    }

    postResNew(
      '/bill/allBillsSelectedByTags',
      {
        tag: _this.tags
      }).then(
      res=>{
        _this.queryMode = true;
        _this.list = res;
      }
    )
    
   
    },
    
    getPageBill(pageIndex){
      this.pageIndex = pageIndex;
      const _this = this;
      getResNew(
        '/bill/allBills/' + pageIndex + '/' + _this.pageSize + '/' + ((_this.input === '') ? 'default': _this.input)).then(
        res => {
          _this.list = res;
        }
      )
    },
    
  },
  watch: {
    input(newQues, oldQues){
      //具体的函数逻辑还是应该封装在methods中：
        if (newQues.length === 0){     
          const _this = this;
        this.list = this.allBillList;
        this.queryMode = false;
        }else{
          this.queryMode = true;
          const _this = this;
      getResNew(
        '/bill/allBills/' + 0 + '/' + 0 + '/' +  _this.input).then(
        res => {
          _this.list = res;
        }
      )
        }
      

    }
  },
  computed:{
   
  },
  components:{
    tag,
    billItem
  }

}
</script>

<style>

	.el-divider__text{
    background-color: #FFEFD5;
}
</style>
<style scoped>

.pageContainer{
  margin-top: 3%;
}
.container{
  max-width: 600px;
  margin: 3% auto auto;
}

.item{
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 5px;

  border-radius: 30px;
  background-color: #FFF;

  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  display: grid;
  grid-template-columns: 73% 27%;
  padding: 13px;/*字和边框的距离*/
  z-index: 1;
}

.title{
  font-weight: bold;
  font-size: 16px;
  display: inline-block;
}

.tag{
  font-size: 12px;
  padding: 2px;
  border:1px solid #20B2AA;
  color: #20B2AA;
  border-radius: 15px;
  margin-left: 5px;
  display: inline-block;
  position: relative;
  top: -10px;
  left: 5px;
}
.amount{
  color: #40E0D0;
  font-size: 23px;
  padding: 7px;
  text-align: right;
  font-weight: bolder;
}

.date{
  margin-top: 7px;
}
.delete{
  width :20px;
  height:20px;
  text-align: center;
  z-index: 3;
  position: absolute;
  top: -6px;
  right: 0px;
  border: 2px solid whitesmoke;
  background-color: whitesmoke;
  border-radius: 50%;
}

.itemContainer{
  position: relative;
}

 #close {
        display: inline-block;
        width: 10px;
        height: 1px;
        background: #333;
        transform: rotate(45deg);
        margin-bottom: 5px;
    }

    #close::after {
        content: '';
        display: block;
        width: 10px;
        height: 1px;
        background: #333;
        transform: rotate(-90deg);
    }
</style>